import React, {useEffect, useState} from "react";
import {CategoryAPI} from "./selector";
import {Col, Row, Spin, Table, Typography} from "antd";
import ImageModal from "../shared/Modal/ImageModal";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setTitle} from "../shared/App/actions";
import CustomTag from "../shared/CustomTag";

const {Text} = Typography;


const CategoryList = () => {

    const columns = [
        {
            dataIndex: 'photo',
            render: (text, data) => {
                if(data.photo.length > 0) {
                    return (<img alt='' className='list-image' src={data.photo[0].url + '?size=thumb'}
                                 data-zoom={data.photo[0].url + '?size=large'}
                                 onClick={handlePreview}/>)
                }
            },
            width: 150,
        },
        {
            title: 'Nazwa',
            dataIndex: 'name',
            onFilter: (value, record) => record.name.indexOf(value) === 0,
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ['descend', 'ascend'],
            render: (text, data) => (
                <div>
                    <Text strong>
                        <Link to={'/category/edit/' + data.id}>{text}</Link> {data.parent_id === null && <CustomTag>główna</CustomTag>}
                    </Text><br/>
                    <Text type="secondary">{data.name_en}</Text><br/>
                    <Text type="secondary">{data.name_de}</Text>
                </div>
            )
        },
        {
            title: 'Status',
            dataIndex: 'active',
            render: (text) => (
                text ?
                    <CustomTag type="success">widoczna</CustomTag>
                    :
                    <CustomTag>niewidoczna</CustomTag>

            ),
            width: 100,
        },
        {
            title: 'Akcje',
            key: 'action',
            render: (text, data) => (
                <span>
                    <Link to={'/category/edit/' + data.id}>edytuj</Link>
              </span>
            ),
            width: 100,
        },
    ];

    const [list, setList] = useState([]);
    const [loader, setLoader] = useState(true);
    const [previewImage, setPreviewImage] = useState(null);
    const [previewVisible, setPreviewVisible] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        CategoryAPI.getCategoryList().then(response => {
            setList(response);
            setLoader(false);
        })

        dispatch(setTitle('Lista kategorii'));
    }, [dispatch]);

    const handlePreview = (event) => {
        setPreviewImage(event.target.dataset.zoom);
        setPreviewVisible(true);
    };

    const handlePreviewCancel = () => {
        setPreviewVisible(false);
    };

    return (
        <>
            <Row>
                <Col className={'portlet__actions'}>
                    {<Link to={"/category/new"}  className={"ant-btn ant-btn-primary"} >dodaj kategorię</Link>}
                </Col>

            </Row>
            {!loader ? <Table
                rowKey={'id'}
                dataSource={list}
                columns={columns}
                pagination={false}
            /> : <Spin />}
            <ImageModal previewImage={previewImage} visible={previewVisible} onCancel={handlePreviewCancel}/>
        </>
    );
}

export {CategoryList as default};