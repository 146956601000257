import React from "react";
import {Upload, message, Modal} from 'antd';
import {PlusOutlined} from '@ant-design/icons';


function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export default class ImageUploader extends React.Component {
    state = {
        loading: false,
        previewVisible: false,
        previewImage: '',
    };

    handleBeforeUpload = () => {
        this.setState({loading: true});
    }

    handleChange = info => {
        const isExtensionValid = info.file.type === 'image/jpeg';
        if (info.file.status !== 'removed' && !isExtensionValid) {
            message.error('Nieporawny typ pliku!');
            return;
        }
        this.props.onChange(info);
        this.setState({loading: false});
    }

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };

    render() {
        const { fileList, limit = 8 } = this.props;
        const { previewVisible, previewImage } = this.state;

        const uploadButton = (
            <div>
                <PlusOutlined />
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        return (
            <div className="clearfix">
                <Upload
                    action={process.env.REACT_APP_API_URL + "image/upload"}
                    withCredentials={true}
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={this.handlePreview}
                    onChange={this.handleChange}
                    beforeUpload={this.handleBeforeUpload}
                >
                    {fileList.length >= limit ? null : uploadButton}
                </Upload>
                <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>
            </div>
        );
    }
}
