import React, {useEffect, useState} from "react";
import {GridProvider} from "../shared/Position/GridContext";
import {DndProvider} from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import FullGrid from "../shared/Position/FullGrid";
import {Button, Select, notification, Row, Col, Spin} from "antd";
import {CategoryAPI} from "../Category/selector";
import {ProductAPI as ProductPositionAPI} from "./selector";
import {useDispatch} from "react-redux";
import {setTitle} from "../shared/App/actions";

const ProductPositions = () => {
    const [categories, setCategories] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [products, setProducts] = useState([]);
    const [productsPositions, setProductsPositions] = useState(null);
    const [loader, setLoader] = useState(true);
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(setTitle('Pozycje produktów'));
        CategoryAPI.getCategoryForSelector().then((response) => {
            setCategories(response);
        });
    }, [dispatch]);

    useEffect(() => {
        setLoader(false);
    }, [products]);

    const handleSave = () => {
        ProductPositionAPI.savePositions(selectedCategory, productsPositions).then(() => {
            notification.success({
                message: 'Zaktualizowano kolejność produktów'
            });
        })


    };

    const handleSelectCategory = (value) => {
        setLoader(true);

        setSelectedCategory(value);
        ProductPositionAPI.getPositions(value).then(response => {
            setProducts(
                response
            );
        }).finally(() => {
            setLoader(false);
        })

    };

    const handleItemsPositionsChanged = (items) => {
        let initialPosition = 1;

        setProductsPositions(items.map(item => {
            return {
                ...item,
                position: initialPosition++
            }
        }));
    }

    if(categories === null) {
        return <></>
    }
    return (
        <>
            <Row>
                <Col className="portlet__actions">
                    <Select className={'positions-wrapper__selector'} placeholder="Wybierz kategorię"
                            onChange={handleSelectCategory}>
                        {categories.map((categoryGroup) => (
                            <Select.OptGroup key={categoryGroup.id} label={categoryGroup.name}>
                                {categoryGroup.category.map(category => {
                                    return <Select.Option key={category.id} value={category.id}>{category.name}</Select.Option>
                                })}
                            </Select.OptGroup>
                        ))}
                    </Select>
                    {products.length > 0 && <Button type="primary" onClick={handleSave}>Zapisz kolejność</Button>}
                </Col>
            </Row>
            <Row>
                <Col>
                    {!selectedCategory && <p className="text-helper">Wybierz kategorię z menu z prawej strony.</p>}
                    <DndProvider backend={HTML5Backend}>
                        <GridProvider items={products} itemsPositionsChanged={handleItemsPositionsChanged}>
                            {loader ? <Spin /> : <FullGrid/> }
                        </GridProvider>
                    </DndProvider>
                </Col>
            </Row>

        </>
    );
}

export {
    ProductPositions as default
}