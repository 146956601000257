import React, {useEffect, useState} from "react";
import {
    Form,
    Button, Col, Row, Select
} from 'antd';
import Input from "antd/es/input";
import {ProductForeignAPI} from "../ProductForeign/selector";

const {TextArea} = Input;

const ProductForeignForm = (props) => {

    const [translations, setTranslations] = useState();
    const [form] = Form.useForm();

    useEffect(() => {
        ProductForeignAPI.getTranslations(props.product.id).then(translations => {
            setTranslations(translations);
        })
    }, [props.product.id])

    const handleSubmit = values => {
        ProductForeignAPI.update(props.product.id, 'DE',{
            name: values.name_DE,
            description: values.description_DE,
            tags: values.tags_DE
        });
        ProductForeignAPI.update(props.product.id, 'EN',{
            name: values.name_EN,
            description: values.description_EN,
            tags: values.tags_EN
        });
    };

    if(!translations) {
        return <></>;
    }
    return (
            <Row>
                <Col md={12}>
                    <h2>Tłumaczenia</h2>
                    <Form
                        layout={'vertical'}
                        form={form}

                        onFinish={handleSubmit}
                        initialValues={{
                            name_EN: translations.EN.name,
                            name_DE: translations.DE.name,
                            description_EN: translations.EN.description,
                            description_DE: translations.DE.description,
                            tags_EN: translations.EN.tags || [],
                            tags_DE: translations.DE.tags || []
                        }}>
                        <h3>Angielski</h3>
                        <Form.Item name={"name_EN"} label='Nazwa' rules={[{required: true, message: 'Podaj nazwę produktu!'}]}>
                            <Input placeholder="nazwa produktu"/>
                        </Form.Item>
                        <Form.Item name={"description_EN"} label='Opis'>
                            <TextArea rows={4}/>
                        </Form.Item>
                        <Form.Item label="Tagi" help="Naciśnij enter albo tab, aby zatwierdzić tag" name="tags_EN">
                            <Select mode="tags" placeholder="dodatkowe frazy po których wyszukiwany będzie produkt">
                            </Select>
                        </Form.Item>

                        <br />
                        <h3>Niemiecki</h3>
                        <Form.Item name={"name_DE"} label='Nazwa' rules={[{required: true, message: 'Podaj nazwę produktu!'}]}>
                            <Input placeholder="nazwa produktu"/>
                        </Form.Item>
                        <Form.Item name={"description_DE"} label='Opis'>
                            <TextArea rows={4}/>
                        </Form.Item>
                        <Form.Item label="Tagi" help="Naciśnij enter albo tab, aby zatwierdzić tag" name="tags_DE">
                            <Select mode="tags" placeholder="dodatkowe frazy po których wyszukiwany będzie produkt">
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Edytuj
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
    );
}

export default ProductForeignForm;