import React from 'react';
import {Modal} from "antd";

export default class ImageModal extends React.Component {

    render() {
        const {previewImage, visible, onCancel} = this.props;

        return (
            <Modal className="show-image" visible={visible} footer={null} onCancel={onCancel}>
                <img alt='' src={previewImage}/>
            </Modal>
        );
    }
}