import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {setTitle} from "../shared/App/actions";
import {Redirect, useParams} from "react-router-dom";
import {APIRequest} from "../shared/APIRequest";
import {notification} from "antd";
import ProductForm from "./ProductForm";

const ProductFormCreate = () => {
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState();

    const {id} = useParams();

    useEffect(() => {
        dispatch(setTitle('Formularz dodawania produktu'));
    }, [dispatch, id]);

    const handleCreate = (values) => {
        APIRequest.post('product', values, (message) => {
            notification.success({
                message: 'Akcja zakończona powodzeniem'
            });
            setTimeout(() => {
                setRedirect(message._links.redirect);
            }, 2000)

        });
    };

    if (redirect) {
        return <Redirect to={redirect}/>
    }
    return (
        <ProductForm onSubmit={handleCreate} payload={{}}/>
    )
};

export {ProductFormCreate as default}