import {APIRequest} from "../shared/APIRequest";

export const CategoryAPI = {
    getCategoryForSelector: () => {
        return APIRequest.get('category/tree');
    },

    getCategory: (id) => {
        return APIRequest.get('category/' + id).then(response => ({
            ...response,
            id: id,
            parent_id: response.parent_id ? response.parent_id.id : null,
            photos: response.photo.length > 0 ? [{
                uid: response.photo[0].id,
                url: response.photo[0].url,
                name: response.photo[0].id,
                status: 'stored',
            }] : [],
        }));
    },

    getCategoryList: () => APIRequest.get('category'),

    getMainCategories: async () => await APIRequest.get('category/main'),
};