import {notification} from "antd";
import axios from 'axios';

axios.defaults.withCredentials = true;

export const APIRequest = {
    get: async (endpoint, params = {}) => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + endpoint, {
                params: params
            });
            return response.data;
        } catch (error) {
            if (error.response.status === 404) {
                window.location.href = '/404';
                return;
            }
            if (error.response.status === 403) {
                window.location.href = '/login';
                return;
            }
            if (error.response && error.response.data.message) {
                const translatedError = intlError(error.response.data.type);
                notification.error({
                    message: translatedError.message
                });
            } else {
                notification.error({
                    message: 'Nieznany błąd'
                });
            }
        }
    },
    post: (endpoint, body, onSuccess) => {
            return axios.post(process.env.REACT_APP_API_URL + endpoint, body)
                .then(response => {
                    if (response.status === 200) {
                        onSuccess(response.data);
                    } else {
                        notification.error({
                            message: 'Nieznany błąd'
                        });
                        return {};
                    }
                })
                .catch((error) => {
                    getOnRejected(error)
                });
    },

    put: async (endpoint, body, onSuccess) => {
        try {
            const response = await axios.put(process.env.REACT_APP_API_URL + endpoint, body);

            if (response.status === 200) {
                onSuccess(response.data);
            } else {
                notification.error({
                    message: 'Nieznany błąd'
                });
            }

        } catch (error) {
            return getOnRejected(error);
        }
    },

    delete: async (endpoint, onSuccess) => {
        try {
            const response = await axios.delete(process.env.REACT_APP_API_URL + endpoint);
            onSuccess(response.data);
            return response.data;

        } catch (error) {
            if (error.response.status === 404) {
                window.location.href = '/404';
                return;
            }
            if (error.response.status === 403) {
                window.location.href = '/login';
                return;
            }
            if (error.response.data.message) {
                const translatedError = intlError(error.response.data.type);
                notification.error({
                    message: translatedError.message
                });
                return null;
            } else {
                notification.error({
                    message: 'Nieznany błąd'
                });
            }
        }
    },
};

const errors = [
    {
        type: 'CategoryNotFound',
        redirect: '/category',
        message: 'Kategoria nie istnieje'
    },
    {
        type: 'ParentCategoryExists',
        redirect: '/category',
        message: 'Kategoria jest powiązana z inną kategorią'
    },
    {
        type: 'ProductAssignedToCategory',
        redirect: '/category',
        message: 'Przynajmniej jeden produkt jest powiązany z tą kategorią'
    },
    {
        type: 'ProductCodeExists',
        message: 'Taki kod jest już przypisany do innego produktu'
    }
];

const intlError = (type) => {
    let foundedErrors = {
        redirect: '/',
        message: 'Nieznany błąd'
    };
    errors.map(element => {
        if (element.type === type) {
            foundedErrors = element;
        }
        return element;
    });
    return foundedErrors;
};

function getOnRejected(error) {
    if (error.response.data.message) {
        const translatedError = intlError(error.response.data.type);
        notification.error({
            message: translatedError.message
        });
        if (translatedError.redirect) {
            window.location.href = translatedError.redirect;
        }

    } else {
        notification.error({
            message: 'Nieznany błąd'
        });
    }
}