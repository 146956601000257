import GridItem, {GridImage, GridImageTitle} from "./GridItem";
import React, {useContext} from "react";
import GridContext from "./GridContext";
import DragItem from "./DragItem";


const FullGrid = () => {
    const {items, moveItem} = useContext(GridContext);

    return (
        <div className={'dnd-grid'}>
            {items.map(item => (
                <DragItem key={item.id} id={item.id} onMoveItem={moveItem}>
                    <GridItem>
                        <GridImage src={item.src} />
                        <GridImageTitle name={item.code + (!item.active ? ' (NIEAKTYWNY)' : '')} />
                    </GridItem>
                </DragItem>
            ))}
        </div>
    );
};

export {
    FullGrid as default
}