import React from "react";
import {Tag} from "antd";

const CustomTag = (props) => {
    let color = '';

    switch(props.type) {
        case 'danger':
            color = '#f50';
            break;
        case 'success':
            color = '#87d068';
            break;
        case 'warning':
            color = '#ffed33';
            break;
        default:
            color = '#ccc';
    }

    return (
        <Tag color={color} >{props.children}</Tag>
    );
}

export {CustomTag as default}