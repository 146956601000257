import {MenuFoldOutlined, MenuUnfoldOutlined} from "@ant-design/icons";
import {Redirect, Route, Switch} from "react-router-dom";
import ProductList from "./components/Product/ProductList";
import ProductFormCreate from "./components/Product/ProductFormCreate";
import ProductFormUpdate from "./components/Product/ProductFormUpdate";
import CategoryList from "./components/Category/CategoryList";
import CategoryFormCreate from "./components/Category/CategoryFormCreate";
import CategoryFormUpdate from "./components/Category/CategoryFormUpdate";
import ContractorList from "./components/Contractor/ContractorList";
import ContractorFormCreate from "./components/Contractor/ContractorFormCreate";
import ContractorFormUpdate from "./components/Contractor/ContractorFormUpdate";
import OfferList from "./components/Offer/OfferList";
import React, {useState} from "react";
import {Layout} from "antd";
import {useSelector} from "react-redux";
import Sidebar from "./components/Layout/Sidebar";
import ProductPositions from "./components/Product/ProductPositions";

const {Header, Content, Footer} = Layout;

const ManagementApp = () => {

    const [collapsed, setCollapsed] = useState(false);

    const toggle = () => {
        setCollapsed(!collapsed);
    };

    const handleTriggerCollapse = (trigger) => {
        setCollapsed(trigger);
    };

    const title = useSelector(state => state.title);

    return (
        <Layout>
            <Sidebar collapsed={collapsed} onBreakpoint={handleTriggerCollapse}/>
            <Layout>
                <Header style={{background: '#fff', padding: 0}}>
                    {collapsed ?
                        <MenuUnfoldOutlined className={"trigger"} onClick={toggle}/>
                        :
                        <MenuFoldOutlined className={"trigger"} onClick={toggle}/>}
                    {title}

                </Header>
                <Content style={{margin: '24px 16px 0', overflow: 'initial'}}>
                    <div style={{padding: 24, background: '#fff'}}>
                        <Switch>
                            <Route path="/product" exact={true} component={ProductList}/>
                            <Route path="/product/new" exact={true} component={ProductFormCreate}/>
                            <Route path="/product/edit/:id" exact={true} component={ProductFormUpdate}/>
                            <Route path="/product/positions" exact={true} component={ProductPositions}/>
                            <Route path="/category" exact={true} component={CategoryList}/>
                            <Route path="/category/new" exact={true} component={CategoryFormCreate}/>
                            <Route path="/category/edit/:id" exact={true} component={CategoryFormUpdate}/>
                            <Route path="/contractor" exact={true} component={ContractorList}/>
                            <Route path="/contractor/new" exact={true} component={ContractorFormCreate}/>
                            <Route path="/contractor/edit/:id" exact={true} component={ContractorFormUpdate}/>
                            <Route path="/offer" exact={true} component={OfferList}/>
                            <Route path="/" exact><Redirect to="/product"/></Route>
                            <Route><Redirect to="/404"/></Route>
                        </Switch>
                    </div>
                </Content>
                <Footer>©2021 ZPK Delta-Rudnik</Footer>

            </Layout>
        </Layout>
    )
}
export default ManagementApp;