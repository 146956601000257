import {APIRequest} from "../shared/APIRequest";

const isAuthorized = async () => {
    try {
        const response =  await APIRequest.get('authorized');
        return response === 'OK';
    } catch (error) {
        return false;
    }
}
export {
    isAuthorized
}