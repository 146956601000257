import React, {useEffect, useState} from "react";
import {
    Form,
    Button, Checkbox, Select
} from 'antd';
import Input from "antd/es/input";
import ImageUploader from "../shared/Form/ImageUploader";
import {Link, useParams} from 'react-router-dom'
import {CategoryAPI} from "./selector";

const {Option} = Select;

const CategoryForm = (props) => {

    const [mainCategories, setMainCategories] = useState([]);
    const {id} = useParams();

    const {name, name_de, name_en, description, description_de, description_en, photos = [], parent_id, active} = props.payload;

    useEffect(() => {

        CategoryAPI.getMainCategories().then(response => {
            if(response) {
                const filtered = response.filter(element => {
                    return element.name !== id;
                });
                setMainCategories(filtered);
            }
        });
    }, [id]);

    const formItemLayout = {
        labelCol: {span: 6},
        wrapperCol: {span: 14},
    };

    const normFile = e => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    return (
        <Form {...formItemLayout} onFinish={props.onSubmit}
              initialValues={{
                  name,
                  name_en,
                  name_de,
                  description,
                  description_en,
                  description_de,
                  active,
                  parent_id,
                  photos
              }}
        >
            <Form.Item label="Nazwa (Polski)" name='name'
                       rules={[{required: true, message: 'Uzupełnij pole!'}]}>
                <Input placeholder="nazwa kategorii"/>
            </Form.Item>
            <Form.Item label="Opis (Polski)" name='description'>
                <Input placeholder="Opis kategorii"/>
            </Form.Item>
            <Form.Item label="Nazwa (Angielski)" name='name_en'
                       rules={[{required: true, message: 'Uzupełnij pole!'}]}>
                <Input placeholder="nazwa kategorii"/>
            </Form.Item>
            <Form.Item label="Opis (Angielski)" name='description_en'>
                <Input placeholder="Opis kategorii"/>
            </Form.Item>
            <Form.Item label="Nazwa (Niemiecki)" name='name_de'
                       rules={[{required: true, message: 'Uzupełnij pole!'}]}>
                <Input placeholder="nazwa kategorii"/>
            </Form.Item>
            <Form.Item label="Opis (Niemiecki)" name='description_de'>
                <Input placeholder="opis kategorii"/>
            </Form.Item>

            <Form.Item label="Kategoria nadrzędna"
                       extra="Jeśli nie zostanie wybrana żadna, kategoria staje się kategorią główną"
                       name='parent_id'>
                <Select placeholder="-- wybierz --" allowClear>
                    {mainCategories.map((element) =>
                        <Option key={element.name} value={element.id}>{element.name}</Option>
                    )}
                </Select>
            </Form.Item>
            <Form.Item wrapperCol={{span: 12, offset: 6}} name='active' valuePropName={'checked'}>
                <Checkbox>
                    Widoczna na stronie
                </Checkbox>
            </Form.Item>
            <Form.Item label="Zdjęcie" name='photos' extra="dozwolone rozszerzenia: jpg" valuePropName="fileList"
                       getValueFromEvent={normFile}
            >
                <ImageUploader limit={1} />
            </Form.Item>


            <Form.Item wrapperCol={{span: 12, offset: 6}}>
                <Button type="primary" htmlType="submit">
                    {id ? "Edytuj" : "Dodaj" }
                </Button>
                <Link to={'/category'}>
                    <Button type="default">
                        Anuluj
                    </Button>
                </Link>

            </Form.Item>
        </Form>
    );
};

export default CategoryForm;