import {APIRequest} from "../shared/APIRequest";

export const ProductAPI = {

    getPositions: (categoryName) => {
        return APIRequest.get('category/'+categoryName+'/products').then(response => {
            return response.map(product => {
                return {
                    id: product.id,
                    code: product.code,
                    active: product.active,
                    src: product.photos.length >0 ? product.photos[0].url : null,
                    position: product.position
                }
            }).sort((productA,productB) => {
                return productA.position - productB.position;
            })
        })
    },

    getProductList: (params) => {
        return APIRequest.get('product', params).then(response => {
            response.products.map(product => ({
                ...product,
                category: product.category.map(category => {
                    return {
                        name: category.name,
                        main: category.parent_id && category.parent_id.name
                    };
                }),
                photos: product.photos.length > 0 ? [{
                    uid: product.photos[0].id,
                    url: product.photos[0].url,
                    name: product.photos[0].id,
                    status: 'stored',
                }] : [],
            }));
            return response;
        });
    },

    getProduct: (productId) => {
        return APIRequest.get('product/' + productId).then(product => {
            return {
                ...product,
                photos: product.photos.map(photo => ({
                    uid: photo.id,
                    url: photo.url,
                    name: photo.id,
                    status: 'stored',
                }))
            }
        });
    },

    savePositions: (category, products) => {
        return APIRequest.post('category/'+category+'/positions', {products: products});
    }
};