import {APIRequest} from "../shared/APIRequest";
import {notification} from "antd";

export const ProductForeignAPI = {

    update: (productId, language, data) => {
        return APIRequest.put('product/foreign/' + productId + '/' + language, data, () => {
            notification.success({
                message: 'Zaktualizowano tłumaczenie.'
            });
        })
    },

    getTranslations: (productId) => APIRequest.get('product/' + productId + '/foreign').then(response => {
        if(response.length === 0) {
            return {
                EN: {
                    name: '',
                    description: '',
                    tags: null
                },
                DE: {
                    name: '',
                    description: '',
                    tags: null
                }
            }
        }
        return response;
    }),
};

export {
    ProductForeignAPI as default
}