import React from "react";

const OfferList = () => {
    return (
        <>
            Sekcja w budowie.
        </>
    );
}

export default OfferList;