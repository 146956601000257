import React, {useEffect, useState} from "react";
import {
    Form,
    Select,
    Button, Checkbox
} from 'antd';
import Input from "antd/es/input";
import ImageUploader from "../shared/Form/ImageUploader";
import DynamicFieldSet from "../shared/Form/DynamicFieldSet";
import {CategoryAPI} from "../Category/selector";

const {TextArea} = Input;

const ProductForm = (props) => {

    const [categories, setCategories] = useState();
    const {category, code, name, description, tags, dimensions, active, photos = []} = props.payload;

    useEffect(() => {
        CategoryAPI.getCategoryForSelector().then((response) => {
            setCategories(response);
        });
    }, []);

    const normFile = e => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const formItemLayout = {
        labelCol: {span: 6},
        wrapperCol: {span: 14},
    };


    return (
        <Form {...formItemLayout} onFinish={props.onSubmit}
            initialValues={{
                category: category && category.map(category => category.id),
                code,
                name,
                description,
                tags,
                active,
                dimensions,
                photos
            }}
        >
            <Form.Item label="Kategorie" hasFeedback name="category"
                       rules={[{required: true, message: 'Podaj kategorię!'}]}>
                <Select placeholder="-- wybierz --" mode={"multiple"}>
                    {categories && categories.map((categoryGroup, index) => (
                        <Select.OptGroup key={index} label={categoryGroup.name}>
                            {categoryGroup.category.map(category => {
                                return <Select.Option key={category.id} value={category.id}>{category.name}</Select.Option>
                            })}
                        </Select.OptGroup>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item label="Kod" name="code"
                       rules={[{required: true, message: 'Podaj kod produktu!'}]}>
                <Input placeholder="kod produktu"/>
            </Form.Item>
            <Form.Item label="Nazwa" name="name" rules={[{required: true, message: 'Podaj nazwę produktu!'}]}>
                <Input placeholder="nazwa produktu"/>
            </Form.Item>
            <Form.Item label="Opis" name="description" >
                <TextArea rows={4}/>
            </Form.Item>
            <Form.Item label="Tagi" help="Naciśnij enter albo tab, aby zatwierdzić tag" name="tags">
                <Select mode="tags" placeholder="dodatkowe frazy po których wyszukiwany będzie produkt">
                </Select>
            </Form.Item>
            <Form.Item label="Wymiary">
                <DynamicFieldSet dimensions={dimensions} />
            </Form.Item>
            <Form.Item wrapperCol={{span: 12, offset: 6}} name='active' valuePropName={'checked'}>
                <Checkbox>
                    Widoczny na stronie
                </Checkbox>
            </Form.Item>
            <Form.Item label="Zdjęcia publiczne"
                       extra="Pierwsze zdjęcie będzie zdjęciem głównym. Dozwolone rozszerzenia: jpg"
                       name="photos"
                       valuePropName="fileList"
                       getValueFromEvent={normFile}>
                <ImageUploader/>
            </Form.Item>
            <Form.Item wrapperCol={{span: 12, offset: 6}}>
                <Button type="primary" htmlType="submit">
                    {code ? "Edytuj" : "Dodaj" }
                </Button>
            </Form.Item>
        </Form>
    );
}

export default ProductForm;