import React, {useEffect, useState} from "react";
import ContractorForm from "./ContractorForm";
import {useDispatch} from "react-redux";
import {setTitle} from "../shared/App/actions";
import {Redirect, useParams} from "react-router-dom";
import ContractorDelete from "./ContractorDelete";
import {notification, Spin} from "antd";
import {APIRequest} from "../shared/APIRequest";
import ContractorAPI from "./selector";

const ContractorFormUpdate = () => {
    const dispatch = useDispatch();
    const {id} = useParams();
    const [payload, setPayload] = useState({});
    const [redirect, setRedirect] = useState();
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        dispatch(setTitle('Formularz kontrahenta'));
        ContractorAPI.getContractor(id).then(response => {
            setPayload(response);
            setLoader(false);
        })


    }, [id, dispatch]);

    const handleSubmit = values => {
        setLoader(true);
        APIRequest.put('contractor/' + id, values, (message) => {

            notification.success({
                message: 'Akcja zakończona powodzeniem'
            });

            setTimeout(() => {
                setRedirect(message._links.redirect);
            }, 2000)
        });

    };

    if (redirect) {
        return <Redirect to={redirect}/>
    }
    if (loader) {
        return <Spin/>;
    }
    if(Object.keys(payload).length > 0) {
        return (
            <>
                <ContractorForm onSubmit={handleSubmit} payload={payload}/>
                <ContractorDelete id={id}/>
            </>
        )
    }

}

export {ContractorFormUpdate as default}