import React from "react";

const GridImage = (props) => {
    return (
        <div className='dnd-grid__image' style={{backgroundImage: `url("${props.src}")`}} />
    )
}

const GridImageTitle = (props) => {
    return (
        <div className="dnd-grid__image-title">
            {props.name}
        </div>
    )
}

const GridItem = ({ forwardedRef, ...props }) => (
    <div className="dnd-grid__item" ref={forwardedRef} {...props} />
);

export {
    GridItem as default,
    GridImage,
    GridImageTitle
}
