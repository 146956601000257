import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {setTitle} from "../shared/App/actions";
import CategoryForm from "./CategoryForm";
import {Redirect, useParams} from "react-router-dom";
import CategoryDelete from "./CategoryDelete";
import {APIRequest} from "../shared/APIRequest";
import {notification, Spin} from "antd";
import {CategoryAPI} from "./selector";

const CategoryFormUpdate = () => {
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState();
    const [payload, setPayload] = useState({});
    const [loader, setLoader] = useState(true);

    const {id} = useParams();

    useEffect(() => {
        dispatch(setTitle('Formularz kategorii'));
        CategoryAPI.getCategory(id).then(response => {
            response && setPayload(response);
            setLoader(false);
        });

    }, [dispatch, id]);

    const handleUpdate = (values) => {
        setLoader(true);
        APIRequest.put('category/' + id, values, (message) => {
            notification.success({
                message: 'Akcja zakończona powodzeniem'
            });
            setTimeout(() => {
                setRedirect(message._links.redirect);
            }, 2000)

        });

    };

    if (redirect) {
        return <Redirect to={redirect}/>
    }
    if (loader) {
        return <Spin/>;
    }
    if (id && Object.keys(payload).length > 0) {
        return (
            <>
                <CategoryForm onSubmit={handleUpdate} payload={payload}/>
                <CategoryDelete id={id}/>
            </>
        )
    }
};

export {CategoryFormUpdate as default}