import React, {useEffect, useState} from "react";
import {
    Form,
    Select,
    Button, notification, Descriptions, Popconfirm, Col, Row, Space
} from 'antd';
import Input from "antd/es/input";
import {ContractorAPI} from "../Contractor/selector";
import {APIRequest} from "../shared/APIRequest";

const {Option} = Select;

const filterDisabled = (payload, contractors = []) => {
    return contractors.map(contractor => {
        payload.forEach(productContractor => {
            if (contractor.id === productContractor.id) {
                contractor.disabled = true;
            }
        });
        return contractor;
    })
}

const ProductContractorForm = (props) => {
    const [payload, setPayload] = useState(props.product.contractor || []);
    const [contractors, setContractors] = useState([]);
    const [form] = Form.useForm();

    useEffect(() => {
        ContractorAPI.getContractors().then(contractors => {
            setContractors(filterDisabled(payload, contractors))
        })
    }, [payload])

    const handleSubmit = values => {
        APIRequest.post('contractor/' + values.contractor + '/' + props.product.id, values, () => {
            notification.success({
                message: 'Pomyślnie dodano kod kontrahenta.'
            });
            form.resetFields();
            const selectedContractor = contractors.filter((contractor) => {
                return contractor.id === values.contractor;
            }).pop();

            setPayload([
                ...payload,
                {
                    id: selectedContractor.id,
                    name: selectedContractor.name,
                    code: values.code
                }
            ]);
            setContractors(contractors.map((contractor) => {
                if (contractor.id === values.contractor) {
                    contractor.disabled = true;
                }
                return contractor;
            }));
        });


    };

    const handleRemove = id => {
        APIRequest.delete('contractor/' + id + '/' + props.product.id, () => {
            notification.success({
                message: 'Usunięto powiązanie.'
            });
            setPayload(payload.filter(contractor => {
                return id !== contractor.id;
            }));
            setContractors(contractors.map((contractor) => {
                if (contractor.id === id) {
                    delete contractor.disabled;
                }
                return contractor;
            }));
        });
    }

    if(!contractors) {
        return <></>;
    }
    return (
        <Space direction="vertical">
            <Row>
                <Col md={24}>
                    <h2>Powiąż kontrahenta</h2>
                    <Form layout="inline" form={form} onFinish={handleSubmit}>
                        <Form.Item hasFeedback name="contractor"
                                   rules={[{required: true, message: 'Wybierz kontrahenta!'}]}>
                            <Select className="form__inline-select" placeholder="-- wybierz kontrahenta --">
                                {contractors.map((contractor) => (
                                    <Option key={contractor.id} value={contractor.id}
                                            disabled={contractor.disabled}>{contractor.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item name={"code"} rules={[{required: true, message: 'Podaj kod produktu!'}]}>
                            <Input placeholder="kod produktu"/>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Dodaj
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col md={24}>
                    <Descriptions bordered size="small">
                        {payload && payload.map(({id, name, code}) => {
                            return (
                                <Descriptions.Item key={id} span={3} label={name}>{code}
                                    <Popconfirm
                                        title="Czy na pewno usunąć powiązanie ?"
                                        onConfirm={() => {
                                            handleRemove(id)
                                        }}
                                        onCancel={() => {
                                        }}
                                        okText="Tak"
                                        cancelText="Nie"
                                        placement="topRight"
                                    >
                                        <Button type="danger" size="small" className="pull-right">
                                            usuń
                                        </Button>
                                    </Popconfirm>
                                </Descriptions.Item>
                            )
                        })}
                    </Descriptions>
                </Col>
            </Row>
        </Space>
    );
}

export default ProductContractorForm;