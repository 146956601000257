import {APIRequest} from "../shared/APIRequest";

export const ContractorAPI = {

    getContractor: (id) => {
        return APIRequest.get('contractor/' + id )
    },

    getContractors: () => APIRequest.get('contractor')
};

export {
    ContractorAPI as default
}