import React from 'react';
import {Spin} from 'antd';
import {APIRequest} from "../shared/APIRequest";

const Logout = () => {
    APIRequest.get('logout').then(() => {
        window.location.href = '/login';
    });
    return <Spin />
};

export default Logout;
