import React from "react";
import {Form, Input, Button} from 'antd';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';

const DynamicFieldSet = (props) => {
    return (
        <Form.List name="dimensions">
            {(fields, {add, remove}) => {
                return (
                    <div>
                        {fields.map((field, index) => (
                            <Form.Item
                                required={false}
                                key={field.key}
                            >
                                <Form.Item
                                    {...field}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                        },
                                    ]}
                                    noStyle
                                >
                                    <Input placeholder="uzupełnij wartością" style={{width: '60%'}}/>
                                </Form.Item>

                                <MinusCircleOutlined
                                    className="dynamic-delete-button"
                                    style={{margin: '0 8px'}}
                                    onClick={() => {
                                        remove(field.name);
                                    }}
                                />
                            </Form.Item>
                        ))}
                        <Form.Item>
                            <Button
                                type="dashed"
                                onClick={() => {
                                    add();
                                }}
                                style={{width: '60%'}}
                            >
                                <PlusOutlined/>
                            </Button>
                        </Form.Item>
                    </div>
                );
            }}
        </Form.List>
    )
};

DynamicFieldSet.defaultProps = {
    dimensions: []
}

export {DynamicFieldSet as default}