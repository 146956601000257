import React, {useEffect, useState} from 'react';
import {Switch, Route, BrowserRouter as Router} from "react-router-dom";
import './App.scss';
import {Layout, Spin} from "antd";
import Topbar from "./components/Layout/Topbar";
import ManagementApp from "./ManagementApp";
import Page404 from "./components/shared/ResultPage/Page404";
import Login from "./components/Security/Login";
import PrivateRoute from "./components/Security/PrivateRoute";
import {isAuthorized} from "./components/Security/Auth";
import Logout from "./components/Security/Logout";

const App = () => {
    const [authorized, setAuthorized] = useState(null);

    useEffect(() => {
        isAuthorized().then(response => {
            setAuthorized(response);
        })
    }, [])

    if (authorized === null) {
        return <Spin/>;
    }

    return (
        <Layout style={{minHeight: '100vh'}}>
            <Topbar/>
            <Router>
                <Switch>
                    <Route path='/404' component={Page404}/>
                    <Route path="/login" exact={true} component={Login}/>
                    <PrivateRoute path="/logout" component={Logout} authed={authorized}/>
                    <PrivateRoute path="/*" component={ManagementApp} authed={authorized}/>
                    <Route component={Page404}/>
                </Switch>
            </Router>
        </Layout>
    );

};

export default App;
