import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {setTitle} from "../shared/App/actions";
import {Redirect, useParams} from "react-router-dom";
import {APIRequest} from "../shared/APIRequest";
import {Divider, notification, Spin} from "antd";
import {ProductAPI} from "./selector";
import ProductDelete from "./ProductDelete";
import ProductContractorForm from "./ProductContractorForm";
import ProductForeignForm from "./ProductForeignForm";
import ProductForm from "./ProductForm";

const ProductFormUpdate = () => {
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState();
    const [payload, setPayload] = useState({});
    const [loader, setLoader] = useState(true);

    const {id} = useParams();

    useEffect(() => {
        dispatch(setTitle('Formularz produktu'));
        ProductAPI.getProduct(id).then(response => {
            response && setPayload(response);
            setLoader(false);
        });

    }, [dispatch, id]);

    const handleUpdate = (values) => {
        setLoader(true);
        APIRequest.put('product/' + id, values, (message) => {
            notification.success({
                message: 'Akcja zakończona powodzeniem'
            });
            setTimeout(() => {
                setRedirect(message._links.redirect);
            }, 1000)

        }).finally(() => {
            setLoader(false);
        });

    };

    if (redirect) {
        return <Redirect to={redirect}/>
    }
    if (loader) {
        return <Spin/>;
    }
    if (id && Object.keys(payload).length > 0) {
        return (
            <>
                <ProductForm onSubmit={handleUpdate} payload={payload}/>
                <Divider/>
                <ProductForeignForm product={payload}/>
                <Divider/>
                <ProductContractorForm product={payload}/>
                <Divider/>
                <ProductDelete id={id}/>
            </>
        )
    }
};

export {ProductFormUpdate as default}