import React from "react";
import {Button, Result} from "antd";
import {Link} from "react-router-dom";

const Page404 = () => {
    return (
        <Result
            status="404"
            title="404"
            subTitle="Ta strona nie istnieje."
            extra={
                <Link to={'/'}>
                    <Button type="primary">Powrót do strony głównej</Button>
                </Link>
            }
        />
    )
};

export {Page404 as default}