import React, {useState} from 'react';
import {Form, Input, Button, Layout} from 'antd';
import {APIRequest} from "../shared/APIRequest";
import Spin from "antd/es/spin";

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

const {Content} = Layout;

const Login = () => {

    const [loader, setLoader] = useState(false);
    const onFinish = values => {
        setLoader(true);
        APIRequest.post('login', values, (response) => {
            window.location.href = '/product';
            setLoader(false);
        });
    };

    return (
        <Layout>
            <Content style={{margin: '24px 16px 0', overflow: 'initial'}}>
                <div style={{padding: 24, background: '#fff'}}>
                <Form
                    {...layout}
                    name="basic"
                    onFinish={onFinish}
                >
                    <Form.Item
                        label="Username"
                        name="username"
                        rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        {loader ? <Spin /> : (
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        )}
                    </Form.Item>
                </Form>
                </div>
            </Content>
        </Layout>
    );
};

export default Login;
