import React from "react";
import Menu from "antd/lib/menu";
import {Avatar, Dropdown, Layout} from "antd";

const {Header} = Layout;

const Topbar = () =>
{
    const menu = (
        <Menu>
            <Menu.Item key="99">
                <a href={'/logout'}>Wyloguj</a>
            </Menu.Item>
        </Menu>
    );

    return (
        <Header className="header">
            <div className="logo">
                DELTA-RUDNIK
            </div>
            <div className="user-top-bar">
                <Dropdown trigger={['click']} overlay={menu}>
                    <div>
                        <Avatar>A</Avatar> admin
                    </div>

                </Dropdown>
            </div>
        </Header>
    );
}

export default Topbar;