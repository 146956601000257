import React, {useState} from "react";
import {
    notification,
    Popconfirm, Typography
} from 'antd';
import {Redirect} from "react-router-dom";
import {APIRequest} from "../shared/APIRequest";

const CategoryDelete = (props) => {

    const [redirect, setRedirect] = useState(false);


    if (redirect) {
        return <Redirect to='/category'/>
    }

    return (
        <>
            <Popconfirm
                title="Czy na pewno usunąć tę kategorię ?"
                onConfirm={() => {
                    APIRequest.delete('category/' + props.id, () => {
                        notification.success({
                            message: 'Usunięto kategorię.'
                        });
                        setRedirect(true);
                    });
                }}
                okText="Tak"
                cancelText="Nie"
                placement="topRight"
            >
                <Typography.Text type="danger" className="clickable" underline>usuń kategorię</Typography.Text>

            </Popconfirm>
        </>
    );
};

export {CategoryDelete as default}