import React, {useCallback, useEffect, useState} from "react";
import {ProductAPI} from "./selector";
import {Col, Row, Input, Table, Typography} from "antd";
import ImageModal from "../shared/Modal/ImageModal";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setTitle} from "../shared/App/actions";
import CustomTag from "../shared/CustomTag";
import _ from "underscore";

const {Text} = Typography;


const ProductList = () => {

    const columns = [
        {
            title: '',
            dataIndex: 'photos',
            render: (text) => {
                if(text.length > 0) {
                    const photo = text[0];
                    return (<img alt='' className='list-image' src={photo.url} data-zoom={photo.url}
                                 onClick={handlePreview}/>);
                }
            },
            width: 150
        },
        {
            title: 'Kod',
            dataIndex: 'code',
            render: (text) => (
                <Text strong>{text}</Text>
            ),
            width: 150
        },
        {
            title: 'Nazwa',
            dataIndex: 'name'
        },
        {
            title: 'Kategoria (kategoria główna)',
            dataIndex: 'category',
            render: (text) => text.map(category => (
                category.name + ' (' + (category.main || 'brak') + ') '
            ))
        },
        {
            title: 'Status',
            dataIndex: 'active',
            render: (text) => (
                text ?
                    <CustomTag type="success">widoczny na stronie</CustomTag>
                    :
                    <CustomTag>niewidoczny</CustomTag>

            ),
            width: 150
        },
        {
            title: 'Akcje',
            key: 'action',
            render: (text, data) => (
                <span>
                    <Link to={'/product/edit/' + data.id}>edytuj</Link>
              </span>
            ),
            width: 100
        },
    ];

    const [list, setList] = useState([]);
    const [loading, setloading] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 25
    });
    const [previewImage, setPreviewImage] = useState(null);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [search, setSearch] = useState('');
    const dispatch = useDispatch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const delayedSearch = useCallback(_.debounce( e => updateList(e), 500), []);

    useEffect(() => {
        dispatch(setTitle('Lista produktów'));
        fetch({
            results: pagination.pageSize,
            page: pagination.current,
            pagination
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handlePreview = (event) => {
        setPreviewImage(event.target.dataset.zoom);
        setPreviewVisible(true);
    };

    const handlePreviewCancel = () => {
        setPreviewVisible(false);
    };

    const handleSearch = (event) => {
        setSearch(event.target.value);
        delayedSearch(event.target.value);
    };

    const updateList = (code) => {
        fetch({
            pagination,
            results: pagination.pageSize,
            page: pagination.current,
            search: code
        });
    }

    const handleTableChange = (pagination) => {
        fetch({
            pagination,
            results: pagination.pageSize,
            page: pagination.current,
            search
        });
    };

    const fetch = ({results, page, pagination, search: code}) => {
        setloading(true);
        ProductAPI.getProductList({results, page, code}).then(data => {
            setloading(false);
            setList(data.products);
            setPagination({
                ...pagination,
                total: data.hits,
            })
        });
    };

    return (
        <div className="product-list">
            <Row>
                <Col className={'portlet__actions'}>
                    <Input.Search className='action__search' placeholder="wyszukaj po kodzie" value={search} onChange={handleSearch}/>
                    {<Link to={"/product/new"}  className={"ant-btn ant-btn-primary"} >dodaj produkt</Link>}
                    {<Link to={"/product/positions"}  className={"ant-btn ant-btn-primary"} >ustal kolejność</Link>}
                </Col>

            </Row>

            <Table
                bordered
                rowKey={'code'}
                dataSource={list}
                columns={columns}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
            />
            <ImageModal previewImage={previewImage} visible={previewVisible} onCancel={handlePreviewCancel}/>
        </div>
    );
};

export default ProductList;