import React from "react";
import {Menu, Layout} from "antd";
import {Link, useLocation} from "react-router-dom";
import {
    ContainerOutlined,
    PictureOutlined,
    ContactsOutlined,
    ProfileOutlined
} from '@ant-design/icons';

const {Sider} = Layout;

const menu = ['product','category','contractor', 'offer'];

const Sidebar = (props) => {
    const location = useLocation();

    let selectedMenu = menu.filter((element) => {
        return location.pathname.includes(element);
    });

    return (
        <Sider breakpoint="md" trigger={null}
               collapsed={props.collapsed} collapsedWidth="0" onBreakpoint={props.onBreakpoint}>

            <Menu mode="inline" theme="light" style={{height: '100%'}} selectedKeys={selectedMenu}>
                <Menu.Item key='product'>

                    <Link to={'/product'}>
                        <ContainerOutlined />
                        <span>Produkt</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key='category'>
                    <Link to={'/category'}>
                        <PictureOutlined />
                        <span>Kategoria</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key='contractor'>
                    <Link to={'/contractor'}>
                        <ContactsOutlined />
                        <span>Kontrahenci</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key='offer'>
                    <Link to={'/offer'}>
                        <ProfileOutlined />
                        <span>Oferty</span>
                    </Link>
                </Menu.Item>
            </Menu>
        </Sider>
    );
}

export {
    Sidebar as default
}