import React, {useEffect, useState} from "react";
import ContractorForm from "./ContractorForm";
import {useDispatch} from "react-redux";
import {setTitle} from "../shared/App/actions";
import {Redirect} from "react-router-dom";
import {notification} from "antd";
import {APIRequest} from "../shared/APIRequest";

const ContractorFormCreate = () => {
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState();

    useEffect(() => {
        dispatch(setTitle('Formularz dodawania kontrahenta'));
    }, [dispatch]);

    const handleSubmit = values => {
        APIRequest.post('contractor', values, (message) => {
            notification.success({
                message: 'Akcja zakończona powodzeniem'
            });
            setTimeout(() => {
                setRedirect(message._links.redirect);
            }, 2000)
        });

    };

    if (redirect) {
        return <Redirect to={redirect}/>
    }

    return (
        <>
            <ContractorForm onSubmit={handleSubmit} payload={{}}/>
        </>
    )
}

export {ContractorFormCreate as default}