import React, {useEffect, useState} from "react";
import ContractorAPI from "./selector";
import {Col, Input, Row, Spin, Table} from "antd";
import {Link} from "react-router-dom";
import {setTitle} from "../shared/App/actions";
import {useDispatch} from "react-redux";

const ContractorList = () => {

    const columns = [
        {
            title: 'Nazwa',
            dataIndex: 'name',
            onFilter: (value, record) => record.name.indexOf(value) === 0,
            sorter: (a, b) => a.name_pl.localeCompare(b.name),
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Akcje',
            key: 'action',
            render: (text, data) => (
                <span>
                    <Link to={'contractor/edit/' + data.id} >edytuj</Link>
              </span>
            ),
            width:100
        },
    ];

    const [list, setList] = useState([]);
    const [search, setSearch] = useState('');
    const [loader, setLoader] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        ContractorAPI.getContractors().then(response => {
            setList(response);
            setLoader(false);
        });
        dispatch(setTitle('Lista kontrahentów'));

    }, [dispatch]);

    const handleSearch = (event) => {
        setSearch(event.target.value);
    }

    const searchedList = list.filter(contractor => {
        return contractor.name.toLowerCase().includes(search.toLowerCase());
    })
    if(loader) {
        return <Spin />;
    }
    return (
        <>
            <Row>
                <Col className="portlet__actions">
                    <Input.Search className='action__search' placeholder="wyszukaj po nazwie" value={search} onChange={handleSearch}/>
                    {<Link to={"/contractor/new"} className={"ant-btn ant-btn-primary"}>Dodaj kontrahenta</Link>}
                </Col>
            </Row>
            <Table
                rowKey={'id'}
                dataSource={searchedList}
                columns={columns}
                pagination={false}
            />
        </>
    );
}

export default ContractorList;