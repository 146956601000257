import React from "react";
import {
    Button,
    Form
} from 'antd';
import Input from "antd/es/input";
import {useParams} from 'react-router-dom'

const ContractorForm = (props) => {

    const {id} = useParams();

    const {name} = props.payload;

    const formItemLayout = {
        labelCol: {span: 6},
        wrapperCol: {span: 14},
    };
    return (
        <Form {...formItemLayout} onFinish={props.onSubmit}>
            <Form.Item name="name" label="Nazwa" rules={[{required: true, message: 'Uzupełnij pole!'}]}
                       initialValue={name}>
                <Input placeholder="Nazwa"/>
            </Form.Item>
            <Form.Item wrapperCol={{span: 12, offset: 6}}>
                <Button type="primary" htmlType="submit">
                    {id ? 'Edytuj' : 'Zapisz'}
                </Button>
            </Form.Item>
        </Form>
    );
};

export default ContractorForm;